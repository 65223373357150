import React from "react";
import { graphql, Link } from "gatsby";
import Image from "gatsby-image";
import ReactMarkdown from "react-markdown/with-html";

//components
// import useSetPageData from "../../utils/useSetPageData";
// import { useStaticQuery, graphql } from "gatsby"
import Hero from "../../components/panels/Hero";
// import OtherServices from "../../components/panels/OtherServices";
import ComponentHeader from "../../components/panels/ComponentHeader";
import Collapsible from "../../components/panels/Collapsible";
import SwitchComponent from "../../components/panels/Switch";
import SEO from "../../components/seo";

//image
import MiddleWare from "../../images/middleware.png";
// import MiddleWareImage from '../../images/middleware-partners.jpg'
import MiddleWare1 from "../../images/nitco-boomi-01.jpg";
import MiddleWareImage from "../../images/nitco-boomi-02.jpg";
import MiddleWare2 from "../../images/boomi-4-21-21-image-2.jpg";
import MiddleWareImage2 from "../../images/Boomi.jpg";
import boomiTempIcon from "../../images/boomi-temp-icon.png";
import JitterbitImage from "../../images/Jitterbit-api-platform-connect-anything-nitco.png";

const BoomiPage = ({ location, data }) => {
  // useSetPageData(location, {
  //   title: `Middleware Integration Developers & iPaaS Software Solutions | Solutions`,
  //   description: `NITCO middleware integration developers use trusted enterprise middleware technologies like Boomi and IBM to build reliable middleware software solutions. `
  // });
  return (
    <>
      <SEO
        {...{
          title: data.strapiPsMiddleware.SEO.tab_title,
          description: data.strapiPsMiddleware.SEO.description
        }}
      />
      <Hero
        heading={"Middleware Integrations"}
        // heading={hero[0].main_heading}
        // boomiTempIcon
        icon={data.allFile.edges[0].node.childImageSharp.fixed}
        // videoImage={hero[0].services_main_image.childImageSharp.fixed.src}
        // videoLink={"/videos/final-cloud.mp4"}
      />
      <div className={`container-fluid`}></div>
      <ComponentHeader
        text={`Integration Solutions for Middleware Software…`}
      />
      <SwitchComponent
        contents={[
          {
            heading:
              "What Is Middleware, and How Your Business Can Leverage It…",
            image: <img src={MiddleWare} />,
            content: (
              <div>
                <p className={`mb-3`}>
                  Middleware is software that bridges the gap between different
                  enterprise tools within your business. It connects data,
                  applications, and users within large organizations.
                </p>
                <br />
                <h4>Purpose of Middleware</h4>
                Middleware is a software layer that sits between the operating
                system and the applications running on it. It is sometimes
                referred to as a “software glue” which connects different
                applications. The primary purpose of the Middleware is to
                provide an infrastructure consisting of core services like
                concurrency, transactions, threading, messaging, and security so
                that organizations can build business applications on top of it.
                Common Middleware examples include database middleware,
                application server middleware, message-oriented middleware, and
                web middleware.
              </div>
            )
          }
          // {
          //   heading: "Our Middleware Stack Partners",
          //   image: <img src={MiddleWareImage} />,
          //   content: (
          //     <div>
          //       <h4>Boomi</h4>
          //       <p className={`mb-3`}>
          //         NITCO is a Boomi partner. Boomi’s enterprise middleware technologies help synchronize client data, connect applications, and automate business processes that advance your company’s ability to integrate your departments fully. NITCO’s expert integrations development team enables you to implement Boomi as an Integration Platform as a Service (iPaaS) to connect your tools and applications efficiently.
          //         </p>
          //       <h4>IBM Sterling</h4>
          //       <p className={`mb-3`}>
          //         Newer companies leverage the cloud like never before, and IBM Middleware facilitates business process management, DevOps, cloud integration, and a reliable application platform that can connect your business data. When it comes to middleware providers, IBM is among the best and most reliable technologies in the game.
          //         </p>
          //     </div>
          //   )
          // }
        ]}
      />
      <ComponentHeader text={`Our Middleware Stack Partners`} />
      <SwitchComponent
        evenClassName={`bg-primary text-light`}
        oddClassName={`bg-light text-primary`}
        contents={[
          {
            heading: "Boomi",
            image: <img src={MiddleWareImage2} />,
            content: (
              <div>
                NITCO is a Boomi partner. Boomi’s enterprise middleware
                technologies help synchronize client data, connect applications,
                and automate business processes that advance your company’s
                ability to integrate your departments fully. NITCO’s expert
                integrations development team enables you to implement Boomi as
                an Integration Platform as a Service (iPaaS) to connect your
                tools and applications efficiently.
              </div>
            )
          },
          {
            heading: "IBM Sterling",
            image: <img src={MiddleWare2} />,
            content: (
              <div>
                Newer companies leverage the cloud like never before, and IBM
                Middleware facilitates business process management, DevOps,
                cloud integration, and a reliable application platform that can
                connect your business data. When it comes to middleware
                providers, IBM is among the best and most reliable technologies
                in the game.
              </div>
            )
          },
          {
            heading: "Jitterbit",
            image: <img src={JitterbitImage} />,
            content: (
              <>
                <div>
                  NITCO proudly partners with Jitterbit, an industry-leading
                  integration platform-as-a-service (iPaaS) provider, to offer
                  our clients a smarter, more strategic approach to
                  enterprise-wide integration. Combining the power of APIs and
                  integration, Jitterbit Harmony enables quick connection to
                  thousands of applications with pre-built templates and
                  workflows to drive process automation and faster business
                  outcomes.
                  <br />
                  <br />
                  <br />
                  Watch this video to learn more{" "}
                  <a
                    style={{ color: "#dc4a46" }}
                    href="https://vimeo.com/633048734"
                  >
                    here
                  </a>
                </div>
              </>
            )
          }
        ]}
      />
      {/* <ComponentHeader
        rootClassName={`bg-light`}
        textClassName={`text-primary`}
        text={"Models"}
      /> */}
      {/* <SwitchComponent
        contents={[
          {
            heading: "Atoms",
            content: (
              <p>
                Atoms are small, self-contained lightweight dynamic runtime
                engines. Nitco will help you design your atoms to be highly
                scalable, powerful, intelligent, and robust to simply solve your
                end-to-end solution. Rather you are on-premise or on the cloud,
                we will configure your connectors, mapping, logics, and error
                handling to meet your needs.
              </p>
            )
          },
          {
            heading: "Molecules",
            content: (
              <p>
                Atoms can be clustered. Boomi refers to the clustering of atoms
                as molecules. If you are processing high volumes of important,
                or except data to jump a thousandfold in the future than we will
                design a molecule for you.
              </p>
            )
          }
        ]}
      /> */}
      {/* Our approach or this below, or something new? from meeting */}
      {/* <ComponentHeader
        rootClassName={`bg-light`}
        textClassName={`text-primary`}
        text={"Atomsphere"}
      />
      <Timeline
        contents={[
          {
            heading: "Build",
            content: "The AtomSphere provides a work area where users can develop cloud-based or on-premise integration processes, called Atoms, through a simple drag and drop interface to allow visual modules. These Atoms transfer data between applications independently of the AtomSphere."
          },
          {
            heading: "Deploy",
            content: "Once an integration process is built and tested in the AtomSphere they can be deployed to an Atom to run on-premise or in the cloud. These Atoms do not rely on the AtomSphere to improve uptime, allowing Atoms to work independently even when the AtomSphere is down for maintenance"
          },
          {
            heading: "Manage",
            content: "The AtomSphere architecture allows for centralized management of all integrations no matter where they are deployed, or how many are processes are run. This provides a complete view of the business and insights to address potentially critical problems."
          }
        ]}
      /> */}
      <ComponentHeader
        rootClassName={`bg-white`}
        textClassName={`text-primary`}
        text={"Learn More"}
      />

      <Collapsible
        contents={[
          {
            heading: "Does Boomi have a strong community?",
            content:
              "Boomi has grown a community of crowd-sourced data mapping suggestions, regression testing, and error resolutions. Boomi Suggest takes away the need for starting from scratch making it easier and faster to modify previously created suggestions to satisfy your needs. Boomi Assure uses customer submitted test data to validate new releases and reduce issues for customers. Finally, Boomi Resolve collects shared intelligence and helps customers resolve errors in integration process failures and helps to rectify the issue."
          },
          {
            heading: " Can I use Boomi with my current security compliance?",
            content:
              "Enables adherence to external audit and compliance priorities such as SOX and SSAE 16 Type II SOC 1 by controlling which users have access to key functions."
          },
          {
            heading: "What application integrations can be supported by Boomi?",
            content: (
              <p>
                Boomi supports a wide range of applications including:
                <br />
                <ul className={"two-columns"}>
                  <li>Salesforce</li>
                  <li>SAP</li>
                  <li>Microsoft Dynamics</li>
                  <li>Mongo DB</li>
                  <li>Amazon S3</li>
                  <li>Oracle E-Business Suite</li>
                  <li>And many more!</li>
                </ul>
              </p>
            )
          }
        ]}
      />

      {/* <OtherServices
        services={["cloud", "it-consulting", "rpa", "virtual-assistant"]}
      /> */}
    </>
  );
};

export default BoomiPage;

export const pageQuery = graphql`
  {
    allFile(filter: { name: { eq: "boomi-temp-icon" } }) {
      edges {
        node {
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    strapiPsMiddleware {
      SEO {
        description
        tab_title
        id
      }
    }
  }
`;
